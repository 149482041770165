import { Button ,Row, Col} from 'antd'
import { PhoneOutlined } from '@ant-design/icons';
import Store from './assets/pngegg.png'
import './home.css'
const Footer =()=>{

return(
  <Row justify="space-between" >
     
  <Col xs={24} sm={12} md={4} lg={4} xl={4}>
 < div className='footerImage'>
 <img src={Store}></img>
 </div>
  </Col>
  <Col xs={24} sm={12} md={3} lg={3} xl={3}>
  <div className='footerLojister'>
  <div className='title '>Lojister</div>
      <div className='options'><p>Hakkımızda</p><p>İletişim</p></div>

  </div>
  </Col>
  <Col xs={24} sm={12} md={4} lg={4} xl={4}>
  <div className='footerHelp'>
  <div className='title '>Yardım</div>
      <div className='options'><p>Sıkça Sorulan Sorular</p></div>
  </div>
  </Col>
  <Col xs={24} sm={12} md={5} lg={5} xl={5}>
  <div className='footerDocs'>
  <div className='title '>Sözleşme ve Belgeler</div>
      <div className='options'><p>Yetki Belgesi</p>
      <p>Gizlilik ve Çerez Politikası</p>
      <p>İptal ve İade Koşulları</p>
      <p>KVKK Hakkında</p>
</div>
</div>
  </Col>
  <Col xs={24} sm={12} md={4} lg={4} xl={4}>
  <div className='footerSupport'>
  <div className='title '>Destek Alın</div>
  <div className='footerButton'>
<Button className="buttonForSupport">
<span className="iconWrapper">
<PhoneOutlined />
</span>
0850 308 2 307
</Button>
<p>info@lojister.com</p>
<Button className="buttonForSupport">
<span className="iconWrapper">
<PhoneOutlined />
</span>
Canlı Destek
</Button>
</div></div>
  
  </Col>

</Row>
)
}
export default Footer