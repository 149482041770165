import React, { useState, useEffect } from 'react';
import Map from './assets/map.png';
import './home.css';

const MapComponent = () => {

return(
    <div className='mapContainer'>
        <div className='mapImage'>
            <img src={Map} style={{width:"70%"}}></img>
            <div className='mapDots'>
                <div className='mapLines'>

                </div>
            </div>
        </div>
    </div>

)
}
export default MapComponent