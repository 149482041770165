import React, { useState, useEffect } from 'react';
import backgroundImage from './assets/background.svg';
import eskitme from './assets/eskitme.jpg'
import logoImage from './assets/logo.svg';
import logoTextImage from './assets/logoyazi.svg';
import logoEskitme from './assets/eskitmelogo.svg';
import background2Image from './assets/background2.svg';
import background3Image from './assets/background3.svg';
import WebFont from 'webfontloader';
import {Button, Input,Row,Col} from 'antd';

import Map from './map'
import Services from './services';
import Intro from './intro';
import Aselsis from './assets/aselsis.png';
import TimTeb from './assets/tim-teb.png';
import Horoz from './assets/horoz-lojistik.png';
import Helmet from './assets/helmet-broker.png';
import Footer from './footer';
import logo from './assets/logoWhite.svg';
import { SearchOutlined  } from '@ant-design/icons';


const MainHome = () => {
    const [showClientHome, setShowClientHome] = useState(false);
    const [logoPosition, setLogoPosition] = useState({ top: '50%', left: '50%' });
    const [logoText, setLogoText] = useState({ top: '50%', left: '50%' });
    const [logoResize, setLogoResize] = useState('%70');
    const [showBackgrounds, setShowBackgrounds] = useState(false);
    const [showLogo,setShowLogo] = useState(false);
    const [showText,setShowText] = useState(false);
    const [backgroundWidths, setBackgroundWidths] = useState({ background2: '50%', background3: '50%' });
    const [fadeOut, setFadeOut] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);
  
    const suffix=<SearchOutlined/>
    const prefix = "Yük bul ve ilan ver..."
    const handleYukVerenClick = () => {
      setBackgroundWidths({ background2: '97%', background3: '3%' });
      document.querySelector('.text-left').classList.add('move');
      document.querySelector('.text-right').classList.add('move');
      document.querySelector('.logo-line').classList.add('move');
      setFadeOut(true);
      setTimeout(()=>{
        setFadeIn(true)
      },1000)
    
      setTimeout(() => {
        setShowClientHome(true);
      }, 1000)
    };
    
    const handleYukTasıyanClick = () => {
      setBackgroundWidths({ background2: '3%', background3: '97%' });
      document.querySelector('.text-left').classList.add('move2');
      document.querySelector('.text-right').classList.add('move2');
      document.querySelector('.logo-line').classList.add('move2');
      setFadeOut(true);
      setTimeout(()=>{
        setFadeIn(true)
      },500)
    };
  
  
    useEffect(() => {
      WebFont.load({
        google: {
          families: ['Poppins:400,700&display=swap']
        }
      });
    }, []);
    useEffect(() => {
      const timer = setTimeout(() => {
        setLogoPosition({ top: '50%', left: '72%', transform: 'translate(-50%, -50%)' });
        setLogoText({ top: '50%', left: '150%', transform: 'translate(-50%, -50%)' });
       
      }, 500);
  
    
    const sizeTimer = setTimeout(() => {
      setLogoResize('300%');
      const updatedPosition = { ...logoPosition, left: '139%',transform: 'translate(-50%, -50%)' };
      setLogoPosition(updatedPosition);
    
      setShowBackgrounds(true);
    }, 1500);
  
    const sizeTimer2 = setTimeout(() => {
      setLogoResize('300%');
      const updatedPosition = { ...logoPosition, left: '139%', transform: 'translate(-50%, -50%)' };
      setLogoPosition(updatedPosition);
     
      
    }, 1750);
    const sizeTimer3 = setTimeout(() => {
      setShowLogo(true)
      setShowText(true)
    }, 2500);
  
    return () => {
      clearTimeout(timer);
      clearTimeout(sizeTimer);
      clearTimeout(sizeTimer2);
      clearTimeout(sizeTimer3)
    };
  }, []);
  
   return(<>{!showClientHome && (
    <div className={`home-page ${fadeOut ? 'fade-out' : ''}`}>
    <img className="background-image" src={backgroundImage} alt="Background" />
    <img className="bgfashion" src={eskitme} alt="Background" />
    {showBackgrounds && (
      <>
        <div
          className="background2-container"
          style={{ width: backgroundWidths.background2 }}
        >
          <img className="background2" src={background2Image} alt="Background 2" />
        </div>
        <div
          className="background3-container"
          style={{ width: backgroundWidths.background3 }}
        >
          <img className="background3" src={background3Image} alt="Background 3" />
        </div>
      </>
    )}
    <div className="logo-container">
      <img
        className={`logo ${showLogo ? "hide-logo" : ""}`}
        src={logoImage}
        alt="Logo"
        style={{ ...logoPosition, width: logoResize }}
      />
      <img className="logo-text" src={logoTextImage} alt="Logo Text" style={logoText} />
      {showLogo && (
        <img className="logo-line" src={logoEskitme} alt="Logo Text" />
      )}
    </div>
    <div className='mainText'>
      {showText && (
        <>
          <div className="text-left" onClick={handleYukVerenClick}>
            Yük Veren
          </div>
          <div className="text-right" onClick={handleYukTasıyanClick} style={{ cursor: "pointer" }}>
            Yük Taşıyan
          </div>
        </>
      )}

    </div>
  </div>)}
  {showClientHome && (
    
   <div className={`clientHome ${fadeIn ? 'fade-in' : ''}`}>
 
   <div className='background'>
      <div className='backgroundRight'></div>
          
      <img className="bgfashion" src={eskitme} alt="Background" />
      <div className='headerContainer'>
          <div className='headerLogo'>
              <img className='logo' src={logo} alt="logo"/>
          </div>
          <div className='headerMenu'>
              <p>Hakkımızda</p>
              <p>İletişim</p> 
              <div className='language'><h4>TR</h4></div>
              <p>Giriş Yap</p> 
              <div className='register'>
                  <Button className="custom-button" size='large'><strong>Üye Ol</strong>   </Button>
              </div>
          </div>
      </div>

      
      <div className='sliderContainer'>
          <div className='sliderLeft'><p>Yük Veren</p></div>
          <div className='sliderSearch'>
              <Input 
                  className='custom-input'
                  size='large'
                  disabled={true}
                  suffix={suffix}
                  prefix={prefix}
                  style={{
                      width: "40%",
                      height: "55px"
                  }}
              />
          </div>
          <div className='sliderMap'>< Map /></div>
          <div className='sliderRight'><p>Yük Taşıyan</p></div>
      </div>
  </div>
  <div className='pageConstruct'>
    
    <Services />
    </div>  

      <div className='introClient'>
          <Intro />
      </div>
  
   
  <section><div className='blogContainer'>
      <div className='blog'>
          <div className='title'>
              <div className='linesandText'>
                  <div className='line1'></div>
                  <div className='text'>ARTIK SEFERLERDEN <br /><b><span>BOŞ DÖNMEK YOK</span></b></div>
                  <div className='line2'></div>
              </div>
          </div>
          <div className='text'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock</div>
          <div className='buttonForAds'><Button className="buttonForAdvs">
      İlanlara Göz Atın
    </Button></div>
      </div></div>
  </section>
  
          <section id="partners">
      <div className="partners-title">
          PARTNERLERİMİZ
      </div>
  
      <Row gutter={[24, 24]}>
          <Col xs={12} md={6}>
              <a href="https://www.horoz.com.tr" target="_blank" rel="noreferrer" className="partner-card">
                  <img src={Horoz} alt="Horoz Lojistik" />
              </a>
          </Col>
  
          <Col xs={12} md={6}>
              <a href="https://www.helmetbroker.com" target="_blank" rel="noreferrer" className="partner-card">
                  <img src={Helmet} alt="Helmet Broker" />
              </a>
          </Col>
  
          <Col xs={12} md={6}>
              <a href="https://aselsis.com" target="_blank" rel="noreferrer" className="partner-card">
                  <img src={Aselsis} alt="Aselsis" />
              </a>
          </Col>
  
          <Col xs={12} md={6}>
              <a href="https://tim.org.tr/tr/duyurular-tim-teb-girisim-evi" target="_blank" rel="noreferrer" className="partner-card">
                  <img src={TimTeb} alt="Tim Teb Girişim Evi" />
              </a>
          </Col>
      </Row>
      
  </section>
  <div className='footerContainer'>
   <div className='footerWidth' ><Footer /></div>
    </div>
  
  </div >
  )}</>
   )
 
};

export default MainHome;