import React from 'react';
import ReactDOM from 'react-dom/client';
import FirstPage from './firstPage';
import reportWebVitals from './reportWebVitals';
import ClientHome from './clientHome'
import MapComponent from './map'
import Services from './services';
import Intro from './intro';
import Main from './mainHome';
import Footer from './footer';
import MainHome from './mainHome'


 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  < MainHome />
  </React.StrictMode>
);

reportWebVitals();
