
import './home.css';
import {Row, Col} from 'antd'
import karsiodeme from './assets/karsıodeme.svg'

const Services = () => {

    return (
      
          <Row justify="space-between">
     
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <div className='services'>
              
              <div className='servicesLine'></div>
              <div className='servicesText'>HİZMETLERİMİZ</div>
            
            <div className='servicesDescription'></div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <div className='services2'>
              
              <div className='image'>
                <img src={karsiodeme} alt="karsiodeme" />
              </div>
              <div className='servicesText'>İŞİNİZİ <br />YÜKLENİYORUZ</div>
              </div>
            <div className='servicesDescription'>ies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letras</div>
          
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <div className='services3'>
              
              <div className='image'>
                <img src={karsiodeme} alt="karsiodeme" />
              </div>
              <div className='servicesText'>ONLİNE NAKLİYE EMTİA SİGORTASI</div>
            </div>
            <div className='servicesDescription'>ets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem </div>
    
        </Col>

      </Row>
     
      );
}
export default Services