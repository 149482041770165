import './home.css';
import {Row, Col} from 'antd';
import image1 from './assets/lojister-1.jpg'
import image2 from './assets/lojister-2.jpg'
const Intro = () => {
  const IntroductionCard = ({ imageRight, imageSrc, title,title2,title3,decsription, }) => {

    return (
        <div className="introductionCard">
            <Row align="middle" style={{ flexDirection: imageRight ? "row-reverse" : "row" }}>
            <Col span={12}>
              <div className='title' >
                <div className='titleBig'>{title}<span style={{ color: '#db5520', display:'block',fontSize:"36px" }}>{title2}</span></div>
               
                <div className='titleSmall'>{title3}</div>
                <div className='line'></div>
                <p className='text'>{decsription}</p>
              </div>
            </Col>
            <Col span={12}>
           <div className='image'>
            <img src={(imageSrc)}></img>
           </div>
            </Col>
            </Row>
        </div>
    );
};
    return(
      
<section id="introduction">
                <IntroductionCard
                    imageSrc={image1}
                    title="LOJİSTİK!"
                    title2="İHTİYACINIZI"
                    title3="KOLAYCA YÖNETİN, TAKİP EDİN ve RAPORLAYIN"
                    decsription="Lojister’ın sunduğu yük - taşıyıcı bulma sistemi ile hem zamandan hem de maliyetten tasarruf ederek diğer işlerinize daha rahat odaklanırsınız."
               
                />

                <IntroductionCard
                    imageRight  
                    imageSrc={image2}
                    title="GÜVENLİ!"
                    title2="LOJİSTİK"
                    title3="SORU İŞARETLERİ OLMADAN"
                    decsription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer to"
               
                />
            </section>
      
    )
}
export default Intro